import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
  Select,
} from "@chakra-ui/react";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa";
import { Fee } from "./Plan.types";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import SwicthForm from "components/SwitchForm";
import { addFeeToPlan, updateFee } from "services/api.service";
import { queryClient } from "index";
import { OriginTaxesDescription } from "types/Fees";
import SelectForm from "components/SelectForm";

const EditFee: FC<{ fee: Fee; disabled: boolean }> = ({ fee, disabled }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [type, setType] = React.useState<string | null>(null);
  const toast = useToast();
  const [maxInstallments, setMaxInstallments] = React.useState(12);
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit, setValue } = useForm<{
    amount: number;
    maxFixed: number;
    minFixed: number;
    releaseInDays: number;

    type: {
      label: string;
      value: string;
    };
    origin: {
      label: string;
      value: string;
    };
    antecipationPercent: number;
    antecipationActive: boolean;
    installmentsType: string;
    maxInstallments: number;
    installments: {
      [key: string]: {
        percent: number;
      };
    };
  }>();

  const save = async (dataForm) => {
    setLoading(true);
    if (!fee.id) {
      addFeeToPlan(fee.planId, {
        ...dataForm,
        type: dataForm.type.value ?? type ?? fee.type,
        origin: dataForm.origin.value,
        releaseInDays: dataForm.releaseInDays ? +dataForm.releaseInDays : 0,
      })
        .then(() => {
          toast({
            title: "Taxa adicionada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          queryClient.refetchQueries({
            queryKey: ["findPlan"],
          });
          onClose();
        })
        .catch((error) => {
          toast({
            title: "Erro ao adicionar taxa",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateFee(fee.id, {
        ...dataForm,
        type: dataForm.type.value ?? type,
        origin: dataForm.origin.value,
        releaseInDays: dataForm.releaseInDays ? +dataForm.releaseInDays : 0,
      })
        .then(() => {
          toast({
            title: "Taxa atualizada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          queryClient.refetchQueries({
            queryKey: ["findPlan"],
          });
          onClose();
        })
        .catch((error) => {
          toast({
            title: "Erro ao atualizar taxa",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setValue("amount", +fee.amount);
    setValue("maxFixed", +fee.maxFixed);
    setValue("minFixed", +fee.minFixed);
    setValue("releaseInDays", +fee.releaseInDays);
    setValue("type", {
      label: fee.type,
      value: fee.type,
    });
    setValue("origin", {
      label: fee.origin,
      value: fee.origin,
    });
    setValue("antecipationPercent", +fee.antecipationPercent);
    setValue("antecipationActive", fee.antecipationActive);
    setType(fee.type);
    setMaxInstallments(fee.maxInstallments ?? 12);
    setValue("installmentsType", fee.installmentsType ?? "FROM_CUSTOMER");
    setValue("maxInstallments", fee.maxInstallments ?? 12);
    setValue("installments", fee.installments ?? {});
  }, [fee, setValue]);

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        mt={2}
        rounded="sm"
        variant="outline"
        isDisabled={disabled}
        width="100%"
        colorScheme="blue"
      >
        <FaPen />
        <Text ml={2}>{fee.id ? "Editar" : "Adicionar"}</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Editar Plano</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Select disabled value={fee.origin}>
                  <option value={fee.origin}>
                    {OriginTaxesDescription[fee.origin]}
                  </option>
                </Select>
                <Select
                  onChange={(data) => {
                    setValue("type", data.target.value as any);
                    setType(data.target.value as any);
                  }}
                >
                  <option value="PERCENTUAL">Percentual</option>
                  <option value="FIXED">Fixo</option>
                </Select>
                {type === "PERCENTUAL" ? (
                  <PercentageNumberInput
                    control={control}
                    name="amount"
                    label="Valor"
                  />
                ) : (
                  <InputNumber control={control} name="amount" label="Valor" />
                )}

                <InputNumber
                  control={control}
                  name="minFixed"
                  label="Valor Mínimo"
                />
                <InputNumber
                  control={control}
                  name="maxFixed"
                  label="Valor Máximo"
                />
                <InputForm
                  type="number"
                  control={control}
                  name="releaseInDays"
                  label="Dias para Liberação"
                />
                <PercentageNumberInput
                  control={control}
                  name="antecipationPercent"
                  label="Percentual de Antecipação"
                />
                <SwicthForm
                  control={control}
                  name={"antecipationActive"}
                  label={"Antecipação Ativa"}
                />
                {["INTEGRATION_CREDIT_CARD"].includes(fee.origin) && (
                  <>
                    <VStack w={"100%"}>
                      <Text>Configuração de Parcelas</Text>

                      <Text>Forma de cobrança da taxa</Text>

                      <SelectForm
                        options={[
                          {
                            label: "Cobrar do cliente",
                            value: "FROM_CUSTOMER",
                          },
                          {
                            label: "Cobrar do pagador",
                            value: "FROM_PAYER",
                          },
                        ]}
                        control={control}
                        name="installmentsType"
                        label="Tipo de cobrança"
                      />

                      <Text>Máximo de parcelas</Text>
                      <InputForm
                        type="number"
                        control={control}
                        name="maxInstallments"
                        label="Máximo de parcelas"
                        onChange={(e) => {
                          setMaxInstallments(e && +e ? +e : 1);
                        }}
                      />

                      <Text>Informe a porcentagem de cada parcela</Text>
                      {new Array(maxInstallments > 1 ? maxInstallments : 0)
                        .fill(0)
                        .map((_, index) => (
                          <HStack w={"100%"}>
                            <PercentageNumberInput
                              key={index}
                              control={control}
                              placeholder="0%"
                              name={`installments.${index + 1}.fee`}
                              label={`Parcela ${index + 1}`}
                              rules={{ required: true }}
                            />
                          </HStack>
                        ))}
                    </VStack>
                  </>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  {fee.id ? "Atualizar" : "Salvar"}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EditFee;
