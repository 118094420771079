import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import PercentageNumberInput from "components/PercentageInput";
import SwicthForm from "components/SwitchForm";
import React from "react";
import { useForm } from "react-hook-form";
import { createCustomerTaxes } from "services/api.service";
import { OriginTaxesDescription, TaxesType } from "types/Fees";
export interface TaxesCustomerForm {
  origin: OriginTaxesDescription;
  type: TaxesType;
  amount: number;
  minFixed: number;
  maxFixed: number;
  releaseInDays: number;
  antecipationPercent: number;
  antecipationActive: boolean;
}

const RegisterTax: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  dataTax?: {
    id: string;
    origin: OriginTaxesDescription;
    minFixed: number;
    maxFixed: number;
    type: TaxesType;
    amount: number;
    releaseInDays: number;
    antecipationPercent: number;
    antecipationActive: boolean;
  };
  customerId: string;
}> = ({ isOpen, onClose, customerId, dataTax }) => {
  const toast = useToast();
  const [typeTax, setTypeTax] = React.useState<TaxesType>(TaxesType.PERCENTUAL);

  const { control, handleSubmit, register, setValue } =
    useForm<TaxesCustomerForm>({
      defaultValues: {
        amount: +(dataTax?.amount || 0),
        origin: dataTax?.origin,
        type: dataTax?.type,
        releaseInDays: dataTax?.releaseInDays,
        minFixed: dataTax?.minFixed,
        maxFixed: dataTax?.maxFixed,
        antecipationActive: dataTax?.antecipationActive,
        antecipationPercent: dataTax?.antecipationPercent,
      },
    });

  const onSubmit = (data: TaxesCustomerForm) => {
    createCustomerTaxes(customerId, {
      ...data,
      id: dataTax?.id,
      releaseInDays: +data.releaseInDays,
    })
      .then(() => {
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Registrar Taxa</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={3}>
            <Text>Caso a taxa já existir, será atualizada.</Text>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                Tipo<Text>*</Text>
              </FormLabel>
              <Select
                placeholder="Selecione o tipo da taxa."
                {...register("type", { required: true })}
                onChange={(e) => {
                  setValue("type", e.target.value as any);
                  setTypeTax(e.target.value as TaxesType);
                }}
              >
                <option value="FIXED">FIXA</option>
                <option value="PERCENTUAL">PERCENTUAL</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                mb="8px"
              >
                Origem<Text>*</Text>
              </FormLabel>
              <Select
                placeholder="Selecione o tipo da taxa."
                {...register("origin", { required: true })}
              >
                <option value="ALL">TODOS</option>
                <option value="CREDIT_PARCELLED">
                  TAXA ADICIONAL POR PARCELA
                </option>
                <option value="DEBIT_CARD">CARTÃO DE DÉBITO</option>
                <option value="DEBIT_VISA_MASTER">
                  CARTÃO DE DÉBITO VISA/MASTER
                </option>
                <option value="DEBIT_ELO">CARTÃO DE DÉBITO ELO</option>
                <option value="CREDIT_CARD">CARTÃO DE CRÉDITO</option>
                <option value="CREDIT_VISA_MASTER">
                  CARTÃO DE CRÉDITO VISA/MASTER
                </option>
                <option value="CREDIT_ELO">CARTÃO DE CRÉDITO ELO</option>
                <option value="CASH">DINHEIRO</option>
                <option value="PIX">PIX</option>
                <option value="TICKET">BOLETO</option>
                <option value="WITHDRAWAL">TAXA DE SAQUE</option>
                <option value="INTEGRATION_TICKET">INTEGRAÇÃO BOLETO</option>
                <option value="INTEGRATION_PIX">INTEGRAÇÃO PIX</option>
                <option value="INTEGRATION_CREDIT_CARD">
                  INTEGRAÇÃO CARTÃO DE CRÉDITO
                </option>
                <option value="INTEGRATION_DEBIT_CARD">
                  INTEGRAÇÃO CARTÃO DE DÉBITO
                </option>
                <option value="INTEGRATION_CASH">INTEGRAÇÃO DINHEIRO</option>
              </Select>
            </FormControl>
            {typeTax === TaxesType.PERCENTUAL ? (
              <PercentageNumberInput
                control={control}
                placeholder="5,99%"
                label={"Valor da taxa "}
                name={`amount`}
                rules={{
                  required: "Campo obrigatório",
                }}
              />
            ) : (
              <InputNumber
                control={control}
                placeholder="5,99%"
                label={"Valor da taxa "}
                name={`amount`}
                rules={{
                  required: "Campo obrigatório",
                }}
              />
            )}

            <InputNumber
              control={control}
              placeholder="R$ 0,00"
              label={"Valor da cobrança mínima"}
              name={`minFixed`}
            />
            <InputNumber
              control={control}
              placeholder="R$ 0,00"
              label={"Valor da cobrança máxima"}
              name={`maxFixed`}
            />
            <InputForm
              control={control}
              placeholder="Quantidade em dias"
              label={"Dias para liberação "}
              type="number"
              name={`releaseInDays`}
              rules={{
                required: "Campo obrigatório",
              }}
            />
            <SwicthForm
              control={control}
              label="Permite Antecipação"
              name="antecipationActive"
            />

            <PercentageNumberInput
              control={control}
              placeholder="Taxa de Antecipação"
              label={"Taxa de Antecipação "}
              name={`antecipationPercent`}
              rules={{
                required: "Campo obrigatório",
              }}
            />
          </VStack>
          <VStack mt={5}>
            <Button
              variant={"outline"}
              w={`100%`}
              color={"green"}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </VStack>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RegisterTax;
