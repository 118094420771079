import { MovementsStatus } from "views/admin/orders";

export interface Maquineta {
  id: string;
  number: string;
  name: string;
}

export interface Customer {
  name: string;
  document_number: string;
}
export interface Customer {
  id: string;
  name: string;
  document_number: string;
}

export interface Maquinetas {
  number: string;
  name: string;
  id: string;
}

export interface Balance {
  id: string;
  customerId: string;
  amount: string;
  amountRefunded: string;
  type: string;
  released: boolean;
  releasedOn: string;
  referenceId: string;
  deletedAt?: any;
  createdAt: string;
  updatedAt: Date;
  Customer: Customer;
  relatedBalanceId?: string;
}

export interface Movement {
  Customer: Customer;
  Maquinetas: Maquinetas;
  amount: string;
  amountLiquid: string;
  amountOriginalLiquid?: string;
  feePercent: string;
  antecipationTax: string;
  createdAt: string;
  orderAt: string;
  id: string;
  maquinetaId: string;
  transactionCode: string;
  taxes: string;
  code: string;
  authorizationCode: string;
  nsu: string;
  installmentCount: number;
  flagCard: string;
  status: MovementsStatus;
  payerName: string;
  type: MovementsType;
  txId?: any;
  chargeId?: any;
  eyeTransactionId?: string;
  integrationType?: any;
  holderName: string;
  balance: Balance[];
}

export enum CanalDeEntrada {
  "WE" = "E-Commerce",
  "N" = "PagSeguro NFC",
  "MD" = "Venda Digitada",
  "MT" = "Venda Tarja",
  "MP" = "Venda com Leitor de Chip e Senha",
  "ME" = "Venda pela Moderninha",
  "WT" = "Aplicativo Carteira PagSeguro",
  "TF" = "TEF",
  "QR" = "QR Code",
  "LK" = "Link",
  "AP" = "Aplicativo PagSeguro",
  "TP" = "Tap On Phone",
}

export enum MovementsType {
  CASH = "CASH",
  PIX = "PIX",
  CREDIT_CARD = "CREDIT_CARD",
  CREDIT_PARCELLED = "CREDIT_PARCELLED",
  DEBIT_CARD = "DEBIT_CARD",
  OTHER = "OTHER",
  TICKET = "TICKET",
  VALE_ALIMENTACAO = "VALE_ALIMENTACAO",
  VALE_REFEICAO = "VALE_REFEICAO",
}

export enum MovementsTypeDescription {
  CASH = "Dinheiro",
  PIX = "Pix",
  CREDIT_CARD = "Cartão de Crédito",
  CREDIT_PARCELLED = "Crédito Parcelado",
  DEBIT_CARD = "Cartão Débito",
  OTHER = "Outros",
  TICKET = "Boleto",
  VALE_ALIMENTACAO = "Vale Alimentação",
  VALE_REFEICAO = "Vale Refeição",
}
